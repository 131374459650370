import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"
CheckWebpFeature()
export { wrapRootElement } from "./gatsby-shared"
export const onRouteUpdate = ({ location, prevLocation }) => { 
    // if (location.pathname.split('?').length > 0) {
    //   window.history.replaceState(null, '', location.pathname);
    // } 
  
    const params = new URLSearchParams(location.search);
    var parameter1 = params.get("transType");
  
    //console.log("pathname", location.pathname)
  
    const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "Dubai";
    const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');
  
    // Residential Redirects
    if(location.pathname === "/properties/for-sale/" || location.pathname === "/properties/for-sale"){
      window.location.replace("/properties/for-sale/in-"+defaultArea_Slug+"/");
    }
  
    if(location.pathname === "/properties/for-rent/" || location.pathname === "/properties/for-rent"){
      window.location.replace("/properties/for-rent/in-"+defaultArea_Slug+"/");
    }
  
  }